import axios from "axios";
import { url } from "../utilities/baseUrl";
import axiosInstance from "../utilities/axiosInstance";

export const createCustomer = async (payload) => {
  const apiUrl = `${url}/admin/user-master/create`;
  try {
    const response = await axiosInstance.post(apiUrl, payload);
    return response;
  } catch (err) {
    return err;
  }
};


export const getAllCustomer = async () => {
  const apiUrl = `${url}/admin/user-master/all-user`;
  try {
    const response = await axiosInstance.get(apiUrl);
    return response;
  } catch (err) {
    return err;
  }
};
