import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Grid,
  InputLabel,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableSortLabel,
  TablePagination,
  Input,
} from "@mui/material";
import { NotificationManager } from "react-notifications";
import { createCustomer, getAllCustomer } from "../../apis/customerService";

const Customer = () => {
  const tableRef = useRef(null);
  const [allCustomerData, setAllCustomerData] = useState([]);
  const [editableIndex, setEditableIndex] = useState(null);
  const [editedRow, setEditedRow] = useState({});
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    username: "",
    phoneNumber: "",
    email: "",
    customerId: "",
    roleName: "",
    password: "",
  });
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClear = () => {
    setFormData({
      firstName: "",
      lastName: "",
      username: "",
      phoneNumber: "",
      email: "",
      customerId: "",
      roleName: "",
      password: "",
    });
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleClickOutside = (event) => {
    if (tableRef.current && !tableRef.current.contains(event.target)) {
      setEditableIndex(null);
      setEditedRow({});
    }
  };

  const handleSort = (column) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(column);
      setSortOrder("asc");
    }
  };

  const sortedData = () => {
    let sorted = allCustomerData ? [...allCustomerData] : [];
    if (sortBy) {
      sorted.sort((a, b) => {
        const firstValue =
          typeof a[sortBy] === "string" ? a[sortBy].toLowerCase() : a[sortBy];
        const secondValue =
          typeof b[sortBy] === "string" ? b[sortBy].toLowerCase() : b[sortBy];
        if (firstValue < secondValue) {
          return sortOrder === "asc" ? -1 : 1;
        }
        if (firstValue > secondValue) {
          return sortOrder === "asc" ? 1 : -1;
        }
        return 0;
      });
    }
    return sorted;
  };

  const fetchAllCustomers = async () => {
    try {
      const allCustomerResponse = await getAllCustomer();
      console.log("cust response: ", allCustomerResponse);
      if (allCustomerResponse.status === 200) {
        setAllCustomerData(allCustomerResponse?.data?.data);
        setLoading(false);
      } else {
        setAllCustomerData([]);
      }
    } catch (error) {
      console.error("Error fetching customers:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    fetchAllCustomers();
  }, []);

  const handleCreateCustomer = async () => {
    const mandatoryFields = [
      formData.firstName,
      formData.lastName,
      formData.username,
      formData.email,
      formData.customerId,
      formData.phoneNumber,
      formData.password,
    ];

    if (mandatoryFields.some((field) => !field)) {
      NotificationManager.warning("Please fill all mandatory fields.", "Error");
      return;
    }

    // if (formData?.phoneNumber.length < 10) {
    //   NotificationManager.warning("Invalid Mobile Number.", "Validation Error");
    //   return;
    // }

    const payload = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      username: formData.username,
      email: formData.email,
      customerId: formData.customerId,
      phoneNumber: formData.phoneNumber,
      password: formData.password,
    };

    try {
      const customerResponse = await createCustomer(payload);
      if (customerResponse.status === 200) {
        NotificationManager.success("Customer created successfully", "Success");
        handleClear();
        fetchAllCustomers();
      } else {
        // NotificationManager.error(
        //   "Error creating customer. Please try again later.",
        //   "Error"
        // );
      }
    } catch (error) {
      NotificationManager.error(
        "Error creating customer. Please try again later.",
        "Error"
      );
    }
  };

  const handleMobileChange = (event) => {
    const regex = /^[0-9]{0,10}$/;
    if (regex.test(event.target.value) || event.target.value === "") {
      setFormData((prevState) => ({
        ...prevState,
        phoneNumber: event.target.value,
      }));
    } else {
      NotificationManager.warning("Only 10 digits are allowed.", "", 500);
    }
  };

//   useEffect(() => {
//     setPage(0);
//   }, [search]);

  const filteredData = sortedData().filter((item) => {
    const searchLower = search.toLowerCase();

    return (
      searchLower === "" ||
      String(item.name).toLowerCase().includes(searchLower) ||
      String(item.email).toLowerCase().includes(searchLower)
    );
  });

  return (
    <Box marginTop={4}>
      <Typography variant="h6" gutterBottom>
        Create Customer:
      </Typography>
      <Box sx={{ m: 4, p: 2, minWidth: "900px", boxShadow: 2 }}>
        <Grid container spacing={2}>
          {/* First Name */}
          <Grid item xs={3}>
            <TextField
              fullWidth
              size="small"
              label="First Name"
              name="firstName"
              backgroundColor="error"
              required
              value={formData.firstName}
              onChange={handleFormChange}
            />
          </Grid>

          {/* Last Name */}
          <Grid item xs={3}>
            <TextField
              fullWidth
              size="small"
              label="Last Name"
              name="lastName"
              backgroundColor="error"
              required
              value={formData.lastName}
              onChange={handleFormChange}
            />
          </Grid>

          {/* Username */}
          <Grid item xs={3}>
            <TextField
              fullWidth
              size="small"
              label="Username"
              name="username"
              backgroundColor="error"
              required
              value={formData.username}
              onChange={handleFormChange}
            />
          </Grid>

          {/* Phone Number */}
          <Grid item xs={3}>
            <TextField
              fullWidth
              size="small"
              label="Phone No."
              name="phoneNumber"
              backgroundColor="error"
              type="number"
              required
              value={formData.phoneNumber}
              onChange={handleMobileChange}
            />
          </Grid>

          {/* Email */}
          <Grid item xs={3}>
            <TextField
              fullWidth
              size="small"
              label="Email"
              name="email"
              type="email"
              backgroundColor="error"
              value={formData.email}
              onChange={handleFormChange}
            />
          </Grid>

          {/* Customer ID */}
          <Grid item xs={3}>
            <TextField
              fullWidth
              size="small"
              label="Customer ID"
              backgroundColor="error"
              name="customerId"
              value={formData.customerId}
              onChange={handleFormChange}
            />
          </Grid>

          {/* Password */}
          <Grid item xs={3}>
            <TextField
              fullWidth
              size="small"
              label="Password"
              name="password"
              type="password"
              backgroundColor="error"
              value={formData.password}
              onChange={handleFormChange}
            />
          </Grid>

          {/* Buttons */}
          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-end">
              <Button
                color="primary"
                size="medium"
                variant="contained"
                onClick={handleCreateCustomer}
                sx={{ marginRight: 1 }}
              >
                Create
              </Button>
              <Button
                color="warning"
                size="medium"
                variant="outlined"
                onClick={handleClear}
              >
                Clear
              </Button>
            </Box>
          </Grid>
        </Grid>

        <Box sx={{ borderRadius: 1, marginTop: 3 }}>
          <TableContainer
            ref={tableRef}
            component={Paper}
            sx={{
              height: 400,
              width: "100%",
              overflowY: "auto",
              "&::-webkit-scrollbar": {
                width: 10,
                height: 10,
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "#fff",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#d5d8df",
                borderRadius: 2,
              },
            }}
          >
            <Table size="small">
              <TableHead>
                <TableRow
                  sx={{
                    backgroundColor: "primary.main",
                  }}
                >
                  <TableCell align="center" style={{ minWidth: "80px" }}>
                    S. No.
                  </TableCell>
                  <TableCell style={{ minWidth: "180px" }}>
                    <TableSortLabel
                      active={sortBy === "customerId"}
                      direction={sortOrder}
                      onClick={() => handleSort("customerId")}
                    >
                      Customer Id
                    </TableSortLabel>
                  </TableCell>

                  <TableCell style={{ minWidth: "180px" }}>
                    <TableSortLabel
                      active={sortBy === "email"}
                      direction={sortOrder}
                      onClick={() => handleSort("email")}
                    >
                      Email
                    </TableSortLabel>
                  </TableCell>
                  <TableCell style={{ minWidth: "150px" }}>
                    <TableSortLabel
                      active={sortBy === "firstName"}
                      direction={sortOrder}
                      onClick={() => handleSort("firstName")}
                    >
                      First Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell style={{ minWidth: "180px" }}>
                    <TableSortLabel
                      active={sortBy === "lastName"}
                      direction={sortOrder}
                      onClick={() => handleSort("lastName")}
                    >
                      Last Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell style={{ minWidth: "180px" }}>
                    <TableSortLabel
                      active={sortBy === "role"}
                      direction={sortOrder}
                      onClick={() => handleSort("role")}
                    >
                      Role
                    </TableSortLabel>
                  </TableCell>
                  <TableCell style={{ minWidth: "180px" }}>
                    <TableSortLabel
                      active={sortBy === "status"}
                      direction={sortOrder}
                      onClick={() => handleSort("status")}
                    >
                      Status
                    </TableSortLabel>
                  </TableCell>

                  {/* <TableCell style={{ minWidth: "150px" }}>
                    Opening Bal.
                  </TableCell> */}
                  <TableCell style={{ minWidth: "180px" }}>
                    <TableSortLabel
                      active={sortBy === "username"}
                      direction={sortOrder}
                      onClick={() => handleSort("username")}
                    >
                      Username
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {allCustomerData !== 0 ? (
                  filteredData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item, index) => (
                      <TableRow key={index}>
                        <TableCell align="center">
                          {page * rowsPerPage + index + 1}
                        </TableCell>
                        <TableCell>{item.customerId}</TableCell>

                        <TableCell>{item.email}</TableCell>

                        <TableCell>{item.firstName}</TableCell>

                        <TableCell>{item.lastName}</TableCell>

                        <TableCell>{item.role}</TableCell>

                        <TableCell>{item.status ? "True" : "False"}</TableCell>
                        <TableCell>{item.username}</TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow
                    sx={{
                      backgroundColor: "#fff",
                    }}
                  >
                    <TableCell colSpan={11} align="center">
                      No Data
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredData?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Customer;
