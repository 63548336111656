import AppRoutes from "./AppRoutes";
import "./App.css";
import 'react-notifications/lib/notifications.css';
import { NotificationContainer } from "react-notifications";

function App() {
  return (
    <div className="App">
      <AppRoutes />
      <NotificationContainer />
    </div>
  );
}

export default App;
