import axios from "axios";
import { url } from "../utilities/baseUrl";

export const login = async (email, password) => {
  const apiUrl = `${url}/admin/user/login`;
  try {
    const response = await axios.post(apiUrl, {
      email,
      password,
    });
    if (response.status === 200) {
      // Store the tokens in cookies
      const { accessToken, refreshToken } = response.data.data;
      document.cookie = `accessToken=${accessToken}; path=/;`;
      document.cookie = `refreshToken=${refreshToken}; path=/;`;
    }
    return response;
  } catch (err) {
    return err;
  }
};
